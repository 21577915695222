'use client';

/* eslint-disable no-unused-vars */
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { TextField } from '@zealy/design-system';
import { CrossCircleLine, SearchLine } from '@zealy/icons';

import { cn } from '#utils/utils';

export interface ISearchContext {
  value: string;
  setValue(value: string): void;
}

const SearchContext = createContext<ISearchContext>({
  value: '',
  setValue: (value: string) => undefined,
});

export function SearchProvider({ children }: PropsWithChildren<unknown>) {
  const query = useSearchParams();

  const [value, setValue] = useState(query.get('q') || '');

  return (
    <SearchContext.Provider
      value={{
        value,
        setValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

/**
 * Exposes the layout variant context for the community cards
 */
export function useSearch() {
  return useContext(SearchContext);
}

/**
 * UI component for search consuming the search context
 */
export const SearchInput = ({ isMobile = false, isLanding = false, className = '' }) => {
  const [value, setValue] = useState('');
  const context = useSearch();

  const pathname = usePathname();
  const ref = useRef<HTMLInputElement>(null);

  const router = useRouter();

  // If we're on the the search page, use the context value
  const searchValue = pathname === '/search' ? context.value : value;
  const setSearchValue =
    pathname === '/search'
      ? context.setValue
      : (value: string) => {
          context.setValue(value);

          setValue(value);
        };

  useEffect(() => {
    const isVisible = () => {
      if (window.innerWidth >= 1280) {
        return !isMobile;
      } else {
        return isMobile;
      }
    };

    // If the search input is not hidden (since we have one in the navbar and one on the page)
    if (pathname === '/search' && isVisible()) {
      ref.current?.setSelectionRange(-1, -1);
      ref.current?.focus();
    }
  }, [pathname, isMobile]);

  if (!isLanding && pathname === '/') {
    return null;
  }

  return (
    <TextField
      ref={ref}
      size={isMobile ? 'lg' : isLanding ? 'xl' : 'md'}
      leftIcon={<SearchLine />}
      rightIcon={
        context.value && !isLanding ? (
          <CrossCircleLine onClick={() => context.setValue('')} />
        ) : undefined
      }
      placeholder="Search for communities"
      name="search"
      type="search"
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          router.push(`/search?${new URLSearchParams({ q: searchValue }).toString()}`);
        }
      }}
      className={cn(
        'w-full',
        isMobile ? 'xl:hidden' : 'hidden xl:flex md:max-w-[270px]',
        isLanding && 'flex !max-w-[368px] w-full',
        className,
      )}
    />
  );
};
